import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { TimelineLite } from 'gsap/TweenMax';
import GougingCarbon from '../svg/gouging-carbon.svg';
import Vivus from 'vivus';
import { GlobalDispatchContext } from '../context/GlobalContextProvider';

const Section1 = styled.section`
  min-height: calc(100vh - 75px);
  position: relative;
  padding-top: 75px;
  width: 100%;
  background-color: #363636;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 25px;
`;

const HeroHeader = styled.h1`
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  font-size: 70rem;
  color: #eee;
  opacity: 0;
  margin: 0 0 15px 0;
  position: relative;
  border-left: 2rem solid #eee;
  padding-left: 25px;
  line-height: 1.2;
  grid-column: 1;
  
  @media screen and (max-width: 1440px) {
    font-size: 64rem;
  }
  
  @media screen and (max-width: 1220px) {
    font-size: 54rem;
  }
  
  @media screen and (max-width: 1020px) {
    font-size: 50rem;
  }
  
  @media screen and (max-width: 900px) {
   grid-column: 1 / 3;
  }
  
  @media screen and (max-width: 900px) {
   grid-column: 1 / 3;
  }
`;

const HeroSubheader = styled.h2`
  font-family: 'Lato', sans-serif;
  font-size: 26rem;
  color: #eee;
  opacity: 0;
  margin: 0;
  position: relative;
  grid-column: 1;
  
  @media screen and (max-width: 1440px) {
    font-size: 24rem;
  }
  
  @media screen and (max-width: 1220px) {
    font-size: 22rem;
  }
  
  @media screen and (max-width: 1020px) {
    font-size: 20rem;
  }
  
  @media screen and (max-width: 900px) {
   grid-column: 1 / 3;
  }
`;

const HeroBody = styled.p`
  font-family: 'Lato Light', sans-serif;
  font-weight: 300;
  font-size: 22rem;
  color: #eee;
  padding-bottom: 0;
  opacity: 0;
  position: relative;
  grid-column: 1;
  
  & + & {
    margin-top: 10px;
  }
  
  @media screen and (max-width: 1440px) {
    font-size: 16rem;
  }

  @media screen and (max-width: 1220px) {
    font-size: 14rem;
  }
  
  @media screen and (max-width: 900px) {
    grid-column: 1 / 3;
  }
  
`;

const HeroWrapper = styled.div`
  display: grid;
  position: relative;
  width: 95%;
  margin: auto;
  flex: 0;
  justify-content: center;
  align-items: center;
  grid-template-columns: 700px auto;
  grid-template-rows: auto auto auto auto auto auto;
  
  @media screen and (max-width: 1220px) {
    grid-template-columns: 600px auto;
  }
  
  @media screen and (max-width: 1020px) {
    grid-template-columns: 550px auto;
  }
  
  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }
  
  @media screen and (max-width: 500px) {
    text-align: center;
  }
`;

const OfferingsContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;  
  grid-template-columns: auto auto;
  
  @media screen and (max-width: 900px) {
    transform: none;
    grid-column: 1 / 3;
    max-width: 400px;
  }
  
  @media screen and (max-width: 500px) {
    justify-self: center;
    width: 100%;
  }
`;

const OfferingsItem = styled.p`
  font-family: 'Lato Light', sans-serif;
  font-weight: 300;
  font-size: 22rem;
  color: #eee;
  margin: 10px 0 0 0;
  position: relative;
  opacity: 0;
  
  @media screen and (max-width: 1440px) {
    font-size: 16rem;
  }

  @media screen and (max-width: 1220px) {
    font-size: 14rem;
  }
`;

const GougingCarbonDiv = styled.div`
  pointer-events: none;
  width: 700px;
  grid-column: 2;
  grid-row: 1 / 7;
  justify-self: flex-end;
  position: relative;
  opacity: 0;

  @media screen and (max-width: 1440px) {
    width: 500px;
  }

  @media screen and (max-width: 1220px) {
    width: 400px;
  }
  
  @media screen and (max-width: 1020px) {
    width: 325px;
  }
  
  @media screen and (max-width: 900px) {
    width: 300px;
    grid-column: 1 / 3;
    grid-row: 3;
    justify-self: center;
  }
`;


const GougingCarbonPage = () => {
  const dispatch = useContext(GlobalDispatchContext);
  const landingElements = [];
  const offerings = [
    'Pointed Gouging Rods (DC)',
    'Jointed Gouging Rods (DC)',
    'Flat Gouging Rods (DC)',
    'Hollow core Gouging Rods (DC)',
    'Blasting Gouging Rods (DC)',
    'Pointed Gouging Rods (AC)',
    'Half Round Gouging Rods (DC)',
    'Projector Carbon (DC)',
    'Gouging Torch',
    'Gouging Torch parts',
  ];

  useEffect(() => {
    dispatch({ type: 'NAV_COLOR_CHANGE', color: 'rgba(54, 54, 54, 0.8)' });
    new Vivus('gouging-carbon-div', {
      start: 'autostart',
      duration: 75,
      file: GougingCarbon,
    }, null);

    let landingTween = new TimelineLite();
    landingTween.to(landingElements, 1, {
        delay: 0.1,
        autoAlpha: 1,
        overwrite: false,
      },
    );

  }, []);

  return (
    <Layout>
      <SEO
        title="Gouging Carbon"
        description="Ceramark Technology Inc. supplies copper coated gouging
        carbon rods in a variety of sizes that can be used with both manual and
        fully automatic torches."
      />
      <Section1>
        <HeroWrapper>
          <HeroHeader ref={li => landingElements[0] = li}>
            Gouging Carbon
          </HeroHeader>
          <HeroSubheader ref={li => landingElements[1] = li}>
            Our gouging carbon rods are used by foundries, factories, and
            workshops across North America.
          </HeroSubheader>
          <HeroBody ref={li => landingElements[2] = li}>
            We supply copper coated gouging carbon rods in a variety of sizes
            and specifications and that can be used with both manual and fully
            automatic torches. These are commonly used in air carbon arc
            gouging, which allows precise control when cutting metal and
            correcting welding defects.
          </HeroBody>
          <HeroBody ref={li => landingElements[3] = li}>
            To maximize product performance and minimize consumption, we source
            exclusively from the largest manufacturer in the world, who has
            been producing high quality rods for over 40 years.
          </HeroBody>
          <HeroSubheader ref={li => landingElements[4] = li}>
            Our product selection includes:
          </HeroSubheader>
          <OfferingsContainer>
            {
              offerings.map((product, i) =>
                <OfferingsItem key={product} ref={li => landingElements[6 + i] = li}>
                  {product}
                </OfferingsItem>,
              )
            }
          </OfferingsContainer>
          <GougingCarbonDiv id="gouging-carbon-div" ref={li => landingElements[5] = li}/>
        </HeroWrapper>
      </Section1>
    </Layout>
  );
};

export default GougingCarbonPage;
